var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "iframe-container-box" },
    [
      _c(
        "div",
        { staticClass: "searchForm" },
        [
          _c("renderForm", {
            ref: "ruleFormRef",
            attrs: {
              formData: _vm.searchForm,
              list: _vm.ruleForm,
              labelWidth: "80",
            },
            on: {
              "update:formData": function ($event) {
                _vm.searchForm = $event
              },
              "update:form-data": function ($event) {
                _vm.searchForm = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "operation",
                fn: function () {
                  return [
                    _c(
                      "rs-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          loading: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.search()
                          },
                        },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "rs-button",
                      {
                        staticClass: "hover-style",
                        attrs: { type: "text", size: "small" },
                        on: { click: _vm.clear },
                      },
                      [_vm._v("重置")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "rs-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.edit("", "create")
                        },
                      },
                    },
                    [_vm._v("添加角色")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "rs-table",
        {
          staticClass: "my-table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "highlight-current-row": "",
            border: "",
            height: "calc(100vh - 150px)",
            "tooltip-effect": "light",
          },
          on: { "row-dblclick": _vm.handleRowClick },
        },
        [
          _c("rs-table-column", {
            attrs: { label: "序号", type: "index", align: "left", width: "70" },
          }),
          _c("rs-table-column", {
            attrs: { prop: "roleCode", label: "角色编号", align: "left" },
          }),
          _c("rs-table-column", {
            attrs: {
              prop: "roleName",
              label: "角色名称",
              "show-overflow-tooltip": "",
              align: "left",
            },
          }),
          _c("rs-table-column", {
            attrs: {
              prop: "remark",
              label: "角色描述",
              "show-overflow-tooltip": "",
              align: "left",
            },
          }),
          _c("rs-table-column", {
            attrs: {
              prop: "createTime",
              label: "创建时间",
              "show-overflow-tooltip": "",
              align: "left",
            },
          }),
          _c("rs-table-column", {
            attrs: {
              prop: "status",
              width: "120",
              formatter: _vm.formatStatus,
              label: "状态",
              align: "left",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "align_right marginTo10" },
        [
          _c("rs-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.pageSize,
              layout: "prev, pager, next,sizes, jumper",
              total: _vm.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }