<template>
  <div class="iframe-container-box">
    <div class="searchForm">
      <renderForm :formData.sync="searchForm" :list="ruleForm" ref="ruleFormRef" labelWidth="80">
        <template v-slot:operation>
          <rs-button type="primary" size="small" @click="search()" :loading="loading">查询</rs-button>
          <rs-button @click="clear" class="hover-style" type="text" size="small">重置</rs-button>
        </template>
      </renderForm>
    </div>

    <div class="box">
      <rs-row>
        <rs-col :span="24">
          <rs-button type="primary" size="mini" @click="edit('', 'create')">添加角色</rs-button>
        </rs-col>
      </rs-row>
    </div>
    <rs-table
      class="my-table"
      :data="tableData"
      highlight-current-row
      border
      height="calc(100vh - 150px)"
      @row-dblclick="handleRowClick"
      tooltip-effect="light"
      style="width: 100%"
    >
      <rs-table-column label="序号" type="index" align="left" width="70"></rs-table-column>
      <rs-table-column prop="roleCode" label="角色编号" align="left"></rs-table-column>
      <rs-table-column prop="roleName" label="角色名称" show-overflow-tooltip align="left"></rs-table-column>
      <rs-table-column prop="remark" label="角色描述" show-overflow-tooltip align="left"></rs-table-column>
      <rs-table-column prop="createTime" label="创建时间" show-overflow-tooltip align="left"></rs-table-column>
      <rs-table-column prop="status" width="120" :formatter="formatStatus" label="状态" align="left"></rs-table-column>
    </rs-table>
    <div class="align_right marginTo10">
      <rs-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="prev, pager, next,sizes, jumper"
        :total="total"
        background
      ></rs-pagination>
    </div>

    <!-- <rs-dialog
                :title="title"
                :visible.sync="drawer"
                direction="rtl"
                :modal=false
                :show-close=false
                size="100%">
            <detail :id="id" :type="type" @cancel="cancel"></detail>
        </rs-dialog> -->
  </div>
</template>

<script>
import Http from '@/api/account'
import utils from '@/utils/utils'
import detail from '../roleManage/roleManageDetail'
export default {
  name: 'roleManageList',
  components: {
    detail
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      form: {
        status: true,
        remarks: '',
        roleName: ''
      },
      visibleFlag: false,
      id: '',
      drawer: false,
      type: '',
      title: '',
      searchForm: {
        roleName: '', //角色名称
        status: '' //角色状态
      },
      ruleForm: [
        {
          type: 'input',
          label: '角色名称',
          key: 'roleName',
          span: '8',
          align: 'left'
        },
        {
          type: 'select',
          label: '角色状态',
          key: 'status',
          span: '8',
          optionList: [
            { value: true, label: '启用' },
            { value: false, label: '冻结' }
          ],
          optionProps: { label: 'label', value: 'value' }
        },

        {
          slot: 'operation'
        }
      ]
    }
  },
  methods: {
    edit(row, type) {
      this.type = type
      if (row && row.id) {
        this.id = row.id
      }
      //let url = `/roleManageDetail/${type}?id=${id}`
      if (type === 'create') {
        this.title = '新建角色'
        this.$router.push(`/roleManageDetail/${type}?id=${this.id}`)
      } else if (type === 'edit') {
        this.title = '编辑角色'
        this.$router.push(`/roleManageDetail/${type}?id=${this.id}`)
      } else if (type === 'view') {
        this.title = '查看角色'
        this.$router.push(`/roleDetail/${type}?id=${this.id}`)
      }
      // this.drawer = true
    },
    // 行点击
    handleRowClick(row, column, event) {
      if (column.label != '操作') {
        this.edit(row, 'view')
      }
    },
    handleAuth(row) {
      let name
      let type = 'role'
      name = `${row.row.roleName} (${row.row.roleCode})`

      // 测试用
      // var data ={"bussinessKey":"23673","processInstanceId":"120059","type":2,"approvalType":2}
      // var data = {"bussinessKey":"3291","type":1}
      // var data = {"bussinessKey":"23178","type":2,"approvalType":2}
      // sessionStorage.setItem('openFlowParams', JSON.stringify(data))
      this.$openDrawer({ url: `/authDetail/${type}?id=${row.row.id}`, title: name, type: 'iframe', size: '62%' })
    },
    search() {
      this.loading = true
      this.currentPage = 1
      this.getList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getList()
    },
    cancel(val) {
      this.drawer = false
      if (val) {
        this.getList()
      }
    },
    getList() {
      let that = this
      Http.getRoleList({
        roleName: that.searchForm.roleName,
        status: that.searchForm.status,
        pageNum: that.currentPage,
        pageSize: that.pageSize
      })
        .then(res => {
          that.total = res.data.data.total
          that.currentPage = res.data.data.pageNum
          that.pageSize = res.data.data.pageSize
          if (res.data.data.list.length >= 0) {
            that.tableData = res.data.data.list
          }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          console.log(error)
        })
    },
    clear() {
      this.searchForm.roleName = ''
      this.searchForm.status = ''
    },
    formatStatus(row, column) {
      return row.status ? '已启用' : '已停用'
    }
  },
  created() {
    let _self = this
    this.getList()
  }
}
</script>

<style scoped lang="less">
.rs-form-item:last-child {
  margin-bottom: 0;
}
.rs-form-item {
  margin-bottom: 0;
}
/deep/.rs-form-item__label {
  color: #666666;
}
/deep/.rs-form-item__label {
  width: 80px;
}
</style>
